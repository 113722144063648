// This is needed because numbers and forward-slash after hash cause a page generated by Gatsby to crash.
// https://github.com/gatsbyjs/gatsby/issues/21120
// Below, we are re-writing modify-hash on PreRouteUpdate

function modifyHash(location) {
  location.hash = "";
  return true;
}

exports.onPreRouteUpdate = ({ location }) => modifyHash(location);
