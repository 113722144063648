module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-71432709-8","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quran with Translations and Recitations","short_name":"Quran","start_url":"/","background_color":"#115293","theme_color":"#115293","display":"standalone","icon":"static/images/quran-multilingual-app-icon.png","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"fd017b2a2b02b333a268ddcca0d2da4a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
